import {
  BusinessIssuer,
  Commerce,
  CreateCommerceRequest,
  DesiredIssuersRequest,
  Issuer,
  UpdateCommerceRequest,
} from '../store/config/types';
import { get, httpDelete, post, put } from './base.service';
import { endpoints } from './endpoints';

export const commercesService = {
  fetchCommerces,
  fetchPossibleInstallments,
  createCommerce,
  updateCommerce,
  deleteCommerce,
  fetchIssuers,
  fetchBusinessIssuers,
  sendDesiredIssuers,
};

async function fetchCommerces(businessId: number): Promise<Commerce[] | undefined> {
  return await get<Commerce[]>(`${endpoints['issuers']}/configuration/${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchPossibleInstallments(): Promise<number[] | undefined> {
  return await get<number[]>(endpoints['installments'])
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createCommerce(createCommerceRequest: CreateCommerceRequest): Promise<Commerce | undefined> {
  return await post<Commerce>(`${endpoints['issuers']}/configuration`, createCommerceRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateCommerce(updateCommerceRequest: UpdateCommerceRequest): Promise<Commerce | undefined> {
  return await put<Commerce>(
    `${endpoints['issuers']}/configuration/${updateCommerceRequest.businessId}/${updateCommerceRequest.issuerId}`,
    updateCommerceRequest,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deleteCommerce(businessId: number, issuerId: number): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints['issuers']}/configuration/${businessId}/${issuerId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchIssuers(): Promise<Issuer[] | undefined> {
  return await get<Issuer[]>(`${endpoints['issuers']}/issuersPaymentProcessors`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchBusinessIssuers(businessId: number): Promise<BusinessIssuer[] | undefined> {
  return await get<BusinessIssuer[]>(`${endpoints['issuers']}/configuration/${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function sendDesiredIssuers(
  businessId: number,
  issuers: DesiredIssuersRequest,
): Promise<boolean | undefined> {
  return await post<boolean>(`${endpoints['issuers']}/${businessId}`, issuers.issuers)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
