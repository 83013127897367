import {
  ADD_SUBBUSINESS,
  FETCH_SUBBUSINESS_FAILED,
  FETCHING_SUBBUSINESS,
  LogoutActionTypes,
  SubBusinessDataTypes,
} from '../config/ActionTypes';
import { SubBusinessState } from '../config/types';

export const initialState: SubBusinessState = {
  loadingSubBusiness: false,
  subBusiness: null,
  subBusinessErrorMessage: null,
  creatingSubBusiness: false,
  createSubBusinessSuccess: false,
  createSubBusinessErrorMessage: null,
  updatingSubBusiness: false,
  updateSubBusinessSuccess: false,
  updateSubBusinessErrorMessage: null,
  deletingSubBusiness: false,
  deleteSubBusinessSuccess: false,
  deleteSubBusinessErrorMessage: null,
};

export function subBusinessReducer(
  state = initialState,
  action: SubBusinessDataTypes | LogoutActionTypes,
): SubBusinessState {
  switch (action.type) {
    case FETCHING_SUBBUSINESS:
      return {
        ...state,
        loadingSubBusiness: true,
        subBusiness: null,
        subBusinessErrorMessage: null,
      };
    case ADD_SUBBUSINESS:
      return {
        ...state,
        loadingSubBusiness: false,
        subBusiness: action.subBusiness,
        subBusinessErrorMessage: null,
      };
    case FETCH_SUBBUSINESS_FAILED:
      return {
        ...state,
        loadingSubBusiness: false,
        subBusiness: null,
        subBusinessErrorMessage: action.error,
      };

    default:
      return state;
  }
}
