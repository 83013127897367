import { createStyles, Divider, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '../../assets/addIcon';
import DeleteIcon from '../../assets/deleteIcon';
import { Client, Commerce, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import SingleCommerceDeleteTooltip from '../Commerces/SingleCommerceDeleteTooltip';
import { CustomSwitch } from '../CustomSwitch';

const useStyles = makeStyles(() =>
  createStyles({
    settingsContainer: {
      padding: '1.3125rem 2rem',
      marginBottom: '1.875rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: styles.white,
      boxShadow: styles.boxShadow,
      borderRadius: '4px',
      color: styles.slateBlue,
      '& span': {
        margin: 0,
      },
    },
  }),
);

interface ClientSettingProps {
  selectedClient: Client;
  handleBillingChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createCommerce: () => void;
  updateCommerce: (commerce: Commerce) => void;
  beginDeleteCommerce: (commerce: Commerce) => void;
}

function ClientSettings({
  selectedClient,
  handleBillingChange,
  createCommerce,
  updateCommerce,
  beginDeleteCommerce,
}: ClientSettingProps) {
  const classes = useStyles();
  const commerces: Commerce[] | null = useSelector((state: RootState) => state.commerces.commerces);

  return (
    <>
      <div className={classes.settingsContainer}>
        <Typography>Habilitar facturación electrónica</Typography>
        <CustomSwitch
          checked={selectedClient!.billingEnabled}
          onChange={handleBillingChange}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
      <Typography variant="h6">Medios de Pago</Typography>
      <div className="commerces-list">
        <div className="new-commerce" onClick={createCommerce}>
          <AddIcon />
          <Typography variant="subtitle2">Nuevo medio de pago</Typography>
        </div>
        {commerces &&
          commerces.map((commerce, index) => {
            return (
              <div className="commerce" onClick={() => updateCommerce(commerce)} key={index}>
                <div className="trash">
                  {commerces && commerces.length === 1 ? (
                    <SingleCommerceDeleteTooltip />
                  ) : (
                    <Tooltip title="Eliminar" placement="top" enterDelay={500}>
                      <span
                        onClick={(e: React.SyntheticEvent<any>) => {
                          e.stopPropagation();
                          beginDeleteCommerce(commerce);
                        }}
                      >
                        <DeleteIcon />
                      </span>
                    </Tooltip>
                  )}
                </div>
                <img src={commerce.issuerImageUrl} alt="logo" />
                <Divider orientation="horizontal" className="divider" />
                <div className="number">
                  <Typography noWrap className="commerce-text">
                    {commerce.issuerName}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default ClientSettings;
