import { createStyles, Divider, makeStyles, Paper, Theme } from '@material-ui/core';
import EditIcon from '../../assets/EditIcon';
import { Commerce } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { StatusPill } from '../StatusPill';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBusinessCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: '10px',
      padding: '0.625rem 1.25rem',
      '& p': {
        textSize: '0.875rem',
        color: styles.slateBlue,
        fontWeight: +styles.semiBoldTextWeight,
      },
    },
    titleAndPaymentMethods: {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        margin: 0,
      },
    },
    paymentMethods: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '0.625rem',
      maxHeight: '1.5rem',
      '& i': {
        fontSize: '0.75rem',
        fontWeight: +styles.normalTextWeight,
        color: styles.iconColor,
      },
    },
    paymentMethodContainer: {
      display: 'flex',
      height: '100%',
      '& img': {
        height: '100%',
      },
    },
    stateAndActions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    paymentMethodDivider: {
      margin: '0 0.75rem',
    },
    divider: {
      margin: '0 0.75rem',
      height: '50%',
      alignSelf: 'unset',
    },
  }),
);

interface SubBusinessCardProps {
  commerceName: string;
  paymentMethods: Commerce[];
}

function SubBusinessCard({ commerceName, paymentMethods }: SubBusinessCardProps) {
  const classes = useStyles();
  const noPaymentMethods = paymentMethods?.length === 0;
  const paymentMethodLimit = 5;
  const hasMorePaymentMethods = paymentMethods?.length > paymentMethodLimit;
  const reducedPaymentMethods = hasMorePaymentMethods
    ? paymentMethods.slice(0, paymentMethodLimit)
    : paymentMethods;

  return (
    <Paper className={classes.subBusinessCard}>
      <div className={classes.titleAndPaymentMethods}>
        <p>{commerceName}</p>
        <div className={classes.paymentMethods}>
          {noPaymentMethods ? (
            <i>no hay medios de pagos activados</i>
          ) : (
            reducedPaymentMethods?.map((paymentMethod, index) => (
              <div className={classes.paymentMethodContainer} key={paymentMethod.issuerId}>
                <img src={paymentMethod.issuerImageUrl} alt="" />
                {index !== paymentMethods?.length - 1 && (
                  <Divider className={classes.paymentMethodDivider} orientation="vertical" flexItem />
                )}
              </div>
            ))
          )}
          {hasMorePaymentMethods && (
            <StatusPill
              isCard
              label={`+${paymentMethods.length - paymentMethodLimit}`}
              color="info"
              customStyles={{ margin: '0 0 0 0.5rem', padding: '3px 0', color: styles.blackText }}
            />
          )}
        </div>
      </div>
      <div className={classes.stateAndActions}>
        <StatusPill
          isCard
          label={noPaymentMethods ? 'Deshabilitado' : 'Habilitado'}
          color={noPaymentMethods ? 'info' : 'success'}
        />
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <EditIcon />
      </div>
    </Paper>
  );
}

export default SubBusinessCard;
