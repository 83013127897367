import {
  ADD_BUSINESS_ISSUERS,
  ADD_COMMERCES,
  ADD_ISSUERS,
  CREATE_COMMERCE_FAILED,
  CREATE_COMMERCE_SUCCESS,
  CREATING_COMMERCE,
  CommercesDataTypes,
  DELETE_COMMERCE_FAILED,
  DELETE_COMMERCE_SUCCESS,
  DELETING_COMMERCE,
  DESIRED_ISSUERS_FAILED,
  DESIRED_ISSUERS_SUCCESS,
  FETCHING_BUSINESS_ISSUERS,
  FETCHING_COMMERCES,
  FETCHING_INSTALLMENTS,
  FETCHING_ISSUERS,
  FETCH_BUSINESS_ISSUERS_FAILED,
  FETCH_COMMERCES_FAILED,
  FETCH_INSTALLMENTS_FAILED,
  FETCH_INSTALLMENTS_SUCCESS,
  FETCH_ISSUERS_FAILED,
  LOGOUT,
  LogoutActionTypes,
  SENDING_DESIRED_ISSUERS,
  UPDATE_COMMERCE_FAILED,
  UPDATE_COMMERCE_SUCCESS,
  UPDATING_COMMERCE,
} from '../config/ActionTypes';
import { Commerce, CommercesState } from '../config/types';

export const initialState: CommercesState = {
  loadingCommerces: false,
  commerces: null,
  commercesErrorMessage: null,
  creatingCommerce: false,
  createCommerceSuccess: false,
  createCommerceErrorMessage: null,
  updatingCommerce: false,
  updateCommerceSuccess: false,
  updateCommerceErrorMessage: null,
  deletingCommerce: false,
  deleteCommerceSuccess: false,
  deleteCommerceErrorMessage: null,
  loadingIssuers: false,
  issuers: null,
  issuersErrorMessage: null,
  businessIssuers: null,
  loadingBusinessIssuers: false,
  businessIssuersErrorMessage: null,
  sendingDesiredIssuers: false,
  desiredIssuersSuccess: false,
  desiredIssuersErrorMessage: null,
  possibleInstallments: null,
  fetchingPossibleInstallments: false,
  fetchInstallmentsError: null,
};

export function commercesReducer(
  state = initialState,
  action: CommercesDataTypes | LogoutActionTypes,
): CommercesState {
  switch (action.type) {
    case FETCHING_COMMERCES:
      return {
        ...state,
        loadingCommerces: true,
        commerces: null,
        commercesErrorMessage: null,
      };

    case ADD_COMMERCES:
      return {
        ...state,
        loadingCommerces: false,
        commerces: action.commerces,
        commercesErrorMessage: null,
      };

    case FETCH_COMMERCES_FAILED:
      return {
        ...state,
        loadingCommerces: false,
        commerces: null,
        commercesErrorMessage: action.error,
      };

    case CREATING_COMMERCE:
      return {
        ...state,
        creatingCommerce: true,
        createCommerceSuccess: false,
        createCommerceErrorMessage: null,
      };

    case CREATE_COMMERCE_SUCCESS:
      const newCommerces = state.commerces ? [...state.commerces] : [];
      newCommerces.push(action.commerce);

      return {
        ...state,
        commerces: newCommerces,
        creatingCommerce: false,
        createCommerceSuccess: true,
        createCommerceErrorMessage: null,
      };

    case CREATE_COMMERCE_FAILED:
      return {
        ...state,
        creatingCommerce: false,
        createCommerceSuccess: false,
        createCommerceErrorMessage: action.error,
      };

    case UPDATING_COMMERCE:
      return {
        ...state,
        updatingCommerce: true,
        updateCommerceSuccess: false,
        updateCommerceErrorMessage: null,
      };

    case UPDATE_COMMERCE_SUCCESS:
      let updatedCommerces = state.commerces!.map((c) => {
        if (c.id === action.commerce.id) {
          let returnedCommerce: Commerce = {
            ...c,
            values: action.commerce.values,
          };

          return returnedCommerce;
        }

        return c;
      });

      return {
        ...state,
        updatingCommerce: false,
        updateCommerceSuccess: true,
        commerces: updatedCommerces,
        updateCommerceErrorMessage: null,
      };

    case UPDATE_COMMERCE_FAILED:
      return {
        ...state,
        updatingCommerce: false,
        updateCommerceSuccess: false,
        updateCommerceErrorMessage: action.error,
      };

    case DELETING_COMMERCE:
      return {
        ...state,
        deletingCommerce: true,
        deleteCommerceSuccess: false,
        deleteCommerceErrorMessage: null,
      };

    case DELETE_COMMERCE_SUCCESS:
      const nonDeletedCommerces =
        state.commerces && state.commerces.filter((c) => c.issuerId !== action.commerceId);

      return {
        ...state,
        deletingCommerce: false,
        deleteCommerceSuccess: true,
        commerces: nonDeletedCommerces,
        deleteCommerceErrorMessage: null,
      };

    case DELETE_COMMERCE_FAILED:
      return {
        ...state,
        deletingCommerce: false,
        deleteCommerceSuccess: false,
        deleteCommerceErrorMessage: action.error,
      };

    case FETCHING_ISSUERS:
      return {
        ...state,
        loadingIssuers: true,
        issuers: null,
        issuersErrorMessage: null,
      };

    case ADD_ISSUERS:
      return {
        ...state,
        loadingIssuers: false,
        issuers: action.issuers,
        issuersErrorMessage: null,
      };

    case FETCHING_BUSINESS_ISSUERS:
      return {
        ...state,
        loadingBusinessIssuers: true,
        businessIssuers: null,
        businessIssuersErrorMessage: null,
      };

    case ADD_BUSINESS_ISSUERS:
      return {
        ...state,
        loadingBusinessIssuers: false,
        businessIssuers: action.issuers,
        businessIssuersErrorMessage: null,
      };

    case FETCH_BUSINESS_ISSUERS_FAILED:
      return {
        ...state,
        loadingBusinessIssuers: false,
        businessIssuers: null,
        businessIssuersErrorMessage: action.error,
      };

    case FETCH_ISSUERS_FAILED:
      return {
        ...state,
        loadingIssuers: false,
        issuers: null,
        issuersErrorMessage: action.error,
      };

    case SENDING_DESIRED_ISSUERS:
      return {
        ...state,
        sendingDesiredIssuers: true,
        desiredIssuersSuccess: false,
        desiredIssuersErrorMessage: null,
      };

    case DESIRED_ISSUERS_SUCCESS:
      return {
        ...state,
        sendingDesiredIssuers: false,
        desiredIssuersSuccess: true,
        desiredIssuersErrorMessage: null,
      };

    case DESIRED_ISSUERS_FAILED:
      return {
        ...state,
        sendingDesiredIssuers: false,
        desiredIssuersSuccess: false,
        desiredIssuersErrorMessage: action.error,
      };

    case FETCHING_INSTALLMENTS:
      return {
        ...state,
        possibleInstallments: null,
        fetchingPossibleInstallments: true,
        fetchInstallmentsError: null,
      };

    case FETCH_INSTALLMENTS_SUCCESS:
      return {
        ...state,
        possibleInstallments: action.installments,
        fetchingPossibleInstallments: false,
        fetchInstallmentsError: null,
      };

    case FETCH_INSTALLMENTS_FAILED:
      return {
        ...state,
        possibleInstallments: null,
        fetchingPossibleInstallments: false,
        fetchInstallmentsError: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
