import { Paper, Theme, createStyles, makeStyles, useMediaQuery } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { CustomSwitch } from '../../components/CustomSwitch';
import { Loading } from '../../components/Loading';
import NewLinkHeader from '../../components/NewLink/Header';
import Inputs from '../../components/NewLink/Inputs';
import LinkActions from '../../components/NewLink/LinkActions';
import { PaymentMethodCard } from '../../components/PaymentMethodCard';
import schemas from '../../data/schemas';
import { dateHelper } from '../../helpers/dateHelper';
import { fetchCommerces } from '../../store/action_creators/commerces.actions';
import { createLink, updateLink } from '../../store/action_creators/links.actions';
import { Currency, LinkTypeEnum, Status, ValidUntil } from '../../store/config/enums';
import {
  AuthState,
  Commerce,
  CommercesState,
  CreateLinkRequest,
  Link,
  LinkValues,
  LinksState,
  RootState,
  UpdateLinkRequest,
} from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { theme } from '../../styles/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: '900px',
      margin: '0 auto',
      paddingBottom: '1.5rem',
    },
    paperRoot: {
      margin: '0',
      padding: '1.875rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'space-between',
      color: styles.slateBlue,
    },
    contentTitle: {
      color: styles.slateBlue,
      fontSize: '1rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: '0 0 1rem 0',
    },
    dateAndTimeContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1.1875rem',
      '& p:last-child': {
        color: styles.blackText,
      },
    },
    paymentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      margin: '1rem 0',
    },
    contentTitleHeader: {
      color: styles.slateBlue,
      fontSize: '1rem',
      fontWeight: Number(styles.semiBoldTextWeight),
      margin: 0,
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '1rem',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& div': {
          marginTop: '1rem',
        },
      },
      '& p': {
        margin: 0,
        fontWeight: Number(styles.semiBoldTextWeight),
      },
      '& span': {
        fontWeight: Number(styles.normalTextWeight),
      },
    },
    paymentSwitchContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& p': {
        margin: 0,
      },
    },
    paymentMethodContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '2%',
      width: '100%',
    },
    error: {
      color: styles.red,
    },
  }),
);

function NewLink() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const linkType = location.pathname.includes('one-use') ? LinkTypeEnum.ONETIME : LinkTypeEnum.PERMANENT;
  const link = (location.state?.link as Link) ?? null;
  const commerces: CommercesState = useSelector((state: RootState) => state.commerces);
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const links: LinksState = useSelector((state: RootState) => state.links);
  const businessId = auth.account?.business.id!;
  const [creatingLink, setCreatingLink] = useState<boolean>(false);
  const [updatingLink, setUpdatingLink] = useState<boolean>(false);
  const [copyLink, setCopyLink] = useState<boolean>(false);
  const [commerceIds, setCommerceIds] = useState<number[]>(
    link && link.installmentsDto ? link.installmentsDto.map((installment) => installment.id) : [],
  );
  const [clickedCopy, setClickedCopy] = useState<boolean | null>(null);

  useEffect(() => {
    dispatch(fetchCommerces(businessId));
  }, [businessId, dispatch]);

  useEffect(() => {
    if (!link && commerces.commerces) {
      setCommerceIds(commerces.commerces?.map((commerce) => Number(commerce.id)));
    }
  }, [commerces, link]);

  useEffect(() => {
    if (copyLink && links.createLinkSuccess && links.createLinkErrorMessage === null && links.latestLink) {
      navigator.clipboard.writeText(links.latestLink.url ?? '');
    }
  }, [copyLink, links.createLinkSuccess, links.createLinkErrorMessage, links.latestLink, links]);

  useEffect(() => {
    if (copyLink && links.updateLinkSuccess && links.updateLinkErrorMessage === null && links.latestLink) {
      navigator.clipboard.writeText(links.latestLink.url ?? '');
    }
  }, [copyLink, links]);

  const sendLinkRequest = (values: LinkValues) => {
    if (link) {
      setUpdatingLink(true);
      const request: UpdateLinkRequest = {
        vatRate: values.vatRate,
        amount: {
          value: values.value,
          currency: values.currency,
        },
        linkType: link.linkType,
        description: values.description !== undefined ? values.description : '',
        status: link.status,
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
        installmentsId: commerceIds,
        reference: values.reference || '',
      };

      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      dispatch(updateLink(request, link.id, auth.account!.business.id!.toString()));
    } else {
      setCreatingLink(true);
      const request: CreateLinkRequest = {
        businessId: auth.account?.business.id !== undefined ? auth.account?.business.id! : 0,
        vatRate: values.vatRate,
        amount: {
          value: values.value,
          currency: values.currency,
        },
        linkType: linkType ? linkType : LinkTypeEnum.PERMANENT,
        userId: auth.account?.userId!,
        description: values.description !== undefined ? values.description : '',
        reference: values.reference || '',
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
        installmentsId: commerceIds,
      };
      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      dispatch(createLink(request));
    }
  };

  const submitLink = (values: LinkValues, actions: any) => {
    clickedCopy ? setCopyLink(true) : setCopyLink(false);

    if (commerceIds.length === 0) {
      actions.setSubmitting(false);
    } else {
      sendLinkRequest(values);
    }
  };

  const handleAddAllPaymenthMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allMethodsArray = event.target.checked
      ? commerces.commerces!.map((commerce: Commerce) => Number(commerce.id))
      : [];
    setCommerceIds(allMethodsArray);
  };

  const closeSnack = () => {
    if (link) {
      setUpdatingLink(false);
      if (links.updateLinkSuccess) navigate(-1);
    } else {
      setCreatingLink(false);
      if (links.createLinkSuccess) navigate(-1);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          currency: link ? link.amount?.currency : Currency.PESO,
          vatRate: link ? link.vatRate : auth.account?.business.vatRate ? auth.account.business.vatRate : 0,
          value: link ? link.amount?.value : 0,
          description: link ? link.description : '',
          reference: link ? link.reference : '',
          validUntil: link && link.validUntil !== null ? ValidUntil.CUSTOM : ValidUntil.NONE,
          validUntilDate: link && link.validUntil ? new Date(link.validUntil) : new Date(),
          installmentsId:
            (link && link.installmentsDto && link.installmentsDto.map((installment) => installment.id)) || [],
        }}
        validationSchema={schemas.LinkSchema}
        onSubmit={(values, actions) => submitLink(values, actions)}
      >
        {({ values }) => {
          return (
            <div className={classes.container}>
              <Form>
                <NewLinkHeader
                  setClickedCopy={setClickedCopy}
                  link={link}
                  linkType={linkType}
                  creatingLink={creatingLink}
                  updatingLink={updatingLink}
                  clickedCopy={clickedCopy}
                />
                <Paper className={classes.paperRoot}>
                  <div className={classes.titleContainer}>
                    <p className={classes.contentTitle}>Información general</p>
                    {link && (
                      <div className={classes.dateAndTimeContainer}>
                        <p>Fecha y hora de creación del link</p>
                        <p>{dateHelper.toShorterNiceString(new Date(link.date))}</p>
                      </div>
                    )}
                  </div>
                  <Inputs values={values} linkType={linkType} />
                  {!commerces.commerces ? (
                    <Loading />
                  ) : (
                    <>
                      <div className={classes.paymentHeader}>
                        <p className={classes.contentTitleHeader}>Medios de Pago</p>
                        <div className={classes.paymentSwitchContainer}>
                          <p>Todos habilitados</p>
                          <CustomSwitch
                            onChange={(event) => handleAddAllPaymenthMethods(event)}
                            checked={commerceIds.length === commerces.commerces?.length}
                          />
                        </div>
                      </div>
                      <p>
                        Elige los medios de pago con los que deseas que puedan realizarse los pagos a través
                        de los links
                      </p>
                      {commerces.commerces && commerceIds.length === 0 && (
                        <p className={classes.error}>Debes seleccionar al menos un medio de pago</p>
                      )}
                      <div className={classes.paymentMethodContainer}>
                        {commerces.commerces?.map((commerce: Commerce) => {
                          return (
                            <PaymentMethodCard
                              key={commerce.id}
                              commerce={commerce}
                              commerceIds={commerceIds}
                              setCommerceIds={setCommerceIds}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </Paper>
                {isMobile && (
                  <LinkActions
                    setClickedCopy={setClickedCopy}
                    link={link}
                    creatingLink={creatingLink}
                    updatingLink={updatingLink}
                    clickedCopy={clickedCopy}
                  />
                )}
              </Form>
            </div>
          );
        }}
      </Formik>
      <CustomSnackbar
        open={creatingLink && (links.createLinkSuccess || links.createLinkErrorMessage !== null)}
        message={
          links.createLinkSuccess
            ? `Se creó ${clickedCopy ? 'y copió ' : ''}el link correctamente`
            : links.createLinkErrorMessage!
        }
        handleClose={closeSnack}
        type={links.createLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
      <CustomSnackbar
        open={updatingLink && (links.updateLinkSuccess || links.updateLinkErrorMessage !== null)}
        message={
          links.updateLinkSuccess ? 'Se actualizó el link correctamente' : links.updateLinkErrorMessage!
        }
        handleClose={closeSnack}
        type={links.updateLinkSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default NewLink;
