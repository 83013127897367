import { Grid, Paper, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import schemas from '../../../data/schemas';
import { issuerHelper } from '../../../helpers/issuerHelper';
import {
  createCommerce,
  fetchIssuers,
  updateCommerce,
} from '../../../store/action_creators/commerces.actions';
import { UserTypeEnum } from '../../../store/config/enums';
import {
  BaseCommerce,
  Client,
  Commerce,
  CreateCommerceRequest,
  Issuer,
  RootState,
  UpdateCommerceRequest,
} from '../../../store/config/types';
import { Loading } from '../../Loading';
import InstallmentsField from '../../dialogs/CommerceDialog/InstallmentsField';
import PlexoFields from '../../dialogs/CommerceDialog/PlexoFields';
import IssuerAndAcquirer from './IssuerAndAcquirer';
import SnackbarManager from './SnackbarManager';
import SoftDescriptor from './SoftDescriptor';
import SubmitButton from './SubmitButton';

interface Values extends Omit<BaseCommerce, 'installments' | 'crossBankTransfers'> {
  issuer: string;
  acquirer: string;
  installments: number;
  crossBankTransfers: boolean;
  commerceNumber: string;
  terminalNumber: string;
}

interface CommerceFormProps {
  handleSuccess: () => void;
  commerce: Commerce | null;
  firstCommerce?: boolean;
  client?: Client;
  isRegisterStep?: boolean;
  isDialog?: boolean;
}

function CommerceForm({
  commerce,
  handleSuccess,
  firstCommerce = false,
  client,
  isRegisterStep = false,
  isDialog = false,
}: CommerceFormProps) {
  const dispatch = useDispatch();
  const { auth, commerces } = useSelector((state: RootState) => state);
  const [creatingCommerce, setCreatingCommerce] = useState<boolean>(false);
  const [updatingCommerce, setUpdatingCommerce] = useState<boolean>(false);
  const [selectedInstallments, setSelectedInstallments] = useState<number[]>(
    commerce ? commerce.values : [1],
  );

  useEffect(() => {
    if (!commerces.issuers) {
      dispatch(fetchIssuers());
    }
  }, [commerces.issuers, dispatch]);

  const isIssuerWithoutInstallments = (selectedIssuer: Issuer | null): boolean => {
    return selectedIssuer
      ? [13, 21, 52, 53, 54, 55, 56, 57, 58, 59].includes(selectedIssuer?.issuerId)
      : false;
  };

  const submitCommerce = (values: Values, helpers: FormikHelpers<Values>) => {
    const crossBankTransfers = values.crossBankTransfers ? 'True' : 'False';

    if (auth.account) {
      const selectedIssuer = commerces.issuers && commerces.issuers.filter((i) => i.id === values.issuer)[0];
      const noInstallmentsIssuer = isIssuerWithoutInstallments(selectedIssuer);

      if (auth.account.type === UserTypeEnum.SUPERADMIN) {
        if (commerce) {
          setUpdatingCommerce(true);
          const updateCommerceRequest: UpdateCommerceRequest = {
            businessId: client!.id,
            issuerId: Number(commerce.issuerId),
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            maxInstallments: values.installments ?? 1,
            softDescriptor: values.softDescriptor?.trim(),
            acquirer: values.acquirer?.trim(),
            accountNumber: values.accountNumber?.trim(),
            crossBankTransfers,
            rut: values.rut?.trim(),
            merchantCategoryCode: values.merchantCategoryCode?.trim(),
          };
          dispatch(updateCommerce(updateCommerceRequest));
        } else {
          setCreatingCommerce(true);

          const createCommerceRequest: CreateCommerceRequest = {
            businessId: client!.id,
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor?.trim(),
            accountNumber: values.accountNumber?.trim(),
            commerceNumber: values.commerceNumber?.trim(),
            terminalNumber: values.terminalNumber?.trim(),
            issuerId: selectedIssuer!.issuerId,
            acquirer: selectedIssuer?.paymentProcessors ? values.acquirer?.trim() : null,
            crossBankTransfers,
            rut: values.rut?.trim(),
            merchantCategoryCode: values.merchantCategoryCode.trim(),
          };
          dispatch(createCommerce(createCommerceRequest));
        }
      } else {
        if (commerce) {
          setUpdatingCommerce(true);

          const updateCommerceRequest: UpdateCommerceRequest = {
            businessId: auth.account.business.id!,
            issuerId: Number(commerce.issuerId),
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor?.trim() ?? '',
            accountNumber: values.accountNumber?.trim() ?? '',
            acquirer: values.acquirer?.trim() ?? '',
            crossBankTransfers,
            rut: values.rut?.trim() ?? '',
            merchantCategoryCode: values.merchantCategoryCode?.trim() ?? '',
          };

          dispatch(updateCommerce(updateCommerceRequest));
        } else {
          setCreatingCommerce(true);

          const createCommerceRequest: CreateCommerceRequest = {
            businessId: auth.account.business.id!,
            maxInstallments: values.installments ?? 1,
            installments: noInstallmentsIssuer ? [1] : selectedInstallments,
            softDescriptor: values.softDescriptor?.trim(),
            accountNumber: values.accountNumber?.trim(),
            commerceNumber: values.commerceNumber?.trim(),
            terminalNumber: values.terminalNumber?.trim(),
            issuerId: selectedIssuer!.issuerId,
            acquirer: selectedIssuer?.paymentProcessors ? values.acquirer : null,
            crossBankTransfers,
            rut: values.rut?.trim(),
            merchantCategoryCode: values.merchantCategoryCode?.trim(),
          };

          dispatch(createCommerce(createCommerceRequest));
        }
      }
    }
  };

  const closeSnack = () => {
    if (commerce) {
      setUpdatingCommerce(false);
    } else {
      setCreatingCommerce(false);
    }

    if (commerces.createCommerceSuccess || commerces.updateCommerceSuccess) {
      handleSuccess();
    }
  };

  const issuersList = issuerHelper.getIssuersList(commerces, commerce);

  if (!commerces.issuers) {
    return <Loading />;
  }

  const renderContent = (noInstallmentsIssuer: boolean, selectedIssuer: Issuer | undefined, fields: any) => (
    <>
      <Typography variant="h6" className={`${isDialog ? 'dialog ' : ''}form-title`}>
        {firstCommerce || isRegisterStep
          ? 'Agrega un primer medio de pago'
          : commerce
          ? 'Editar medio de pago'
          : 'Agregar nuevo medio de pago'}
      </Typography>
      {(isRegisterStep || firstCommerce) && (
        <Typography className="first-payment-method-text">
          Antes de poder empezar a operar deberás agregar un primer medio de pago. ¿Aún no tienes códigos de
          comercio? <Link to="/request-issuers">Solicítalo aquí</Link>
        </Typography>
      )}
      <Grid container spacing={2}>
        <IssuerAndAcquirer
          commerce={commerce}
          installmentsField={
            !noInstallmentsIssuer && (
              <InstallmentsField
                commerce={commerce}
                selectedInstallments={selectedInstallments}
                setSelectedInstallments={setSelectedInstallments}
              />
            )
          }
          selectedIssuer={selectedIssuer!}
        />
        {!isRegisterStep && !firstCommerce && !commerce && (
          <Typography variant="subtitle1" component="p">
            ¿Necesitas un código de comercio?{' '}
            <Link to="/request-issuers">
              <u>Solicítalo aquí</u>
            </Link>
          </Typography>
        )}
        <SoftDescriptor commerce={commerce} />
        <PlexoFields
          fields={fields}
          commerce={commerce}
          isRegisterStep={isRegisterStep}
          firstCommerce={firstCommerce}
        />
      </Grid>
    </>
  );

  return (
    <Formik
      initialValues={{
        issuer:
          commerce && commerce.issuerId
            ? commerce.issuerId.toString()
            : issuersList
            ? issuersList.filter((il) => !il.disabled)[0].id
            : '4',
        acquirer: (commerce && commerce.fields?.paymentProcessorId) || '',
        installments: commerce ? Math.max(...commerce.values) : 1,
        softDescriptor: (commerce && commerce.fields?.softDescriptor) || '',
        accountNumber: (commerce && commerce.fields?.accountNumber) || '',
        commerceNumber: (commerce && commerce.fields?.providerCommerceNumber) || '',
        terminalNumber: (commerce && commerce.fields?.terminalNumber) || '',
        crossBankTransfers:
          (commerce && commerce.fields?.crossBankTransfers === 'True' ? true : false) || false,
        rut: (commerce && commerce.fields?.rut) || '',
        merchantCategoryCode: (commerce && commerce.fields?.merchantCategoryCode) || '',
      }}
      validationSchema={schemas.CommerceSchema}
      onSubmit={submitCommerce}
      enableReinitialize={true}
    >
      {({ values }) => {
        const selectedIssuer =
          commerces.issuers && commerces.issuers.filter((i) => i.id === values.issuer)[0];

        const selectedAcquirer = selectedIssuer?.paymentProcessors
          ? selectedIssuer?.paymentProcessors.filter((pp) => pp.acquirer === values.acquirer)[0]
          : '';

        const fields = selectedAcquirer
          ? selectedAcquirer.fields
          : !selectedIssuer || !selectedIssuer.paymentProcessors
          ? selectedIssuer?.fields
          : commerce
          ? selectedIssuer?.fields
          : [];

        const noInstallmentsIssuer = isIssuerWithoutInstallments(selectedIssuer);

        const isSubmitButtonDisabled = (!commerce && !values.softDescriptor) || !values.acquirer;

        return (
          <Form className="form commerce-form" id="commerce-form">
            {isRegisterStep ? (
              <Paper elevation={2} className="paper-container">
                {renderContent(noInstallmentsIssuer, selectedIssuer!, fields)}
              </Paper>
            ) : (
              renderContent(noInstallmentsIssuer, selectedIssuer!, fields)
            )}
            <SubmitButton
              isRegisterStep={isRegisterStep}
              creatingCommerce={creatingCommerce}
              updatingCommerce={updatingCommerce}
              commerce={commerce}
              disabled={isSubmitButtonDisabled}
            />
            <SnackbarManager
              creatingCommerce={creatingCommerce}
              updatingCommerce={updatingCommerce}
              handleClose={closeSnack}
              firstCommerce={firstCommerce}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default CommerceForm;
