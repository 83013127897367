import {
  Business,
  BusinessRequest,
  ClientSecretRequest,
  UpdateBusinessRequest,
  UpdateImageRequest,
} from '../store/config/types';
import { get, httpDelete, postForm, put, putForm } from './base.service';
import { endpoints } from './endpoints';

export const businessService = {
  fetchBusinesses,
  createBusiness,
  updateBusiness,
  updateBusinessPicture,
  removeBusinessPicture,
  getBusiness,
  updateClientSecret,
};

async function fetchBusinesses(): Promise<Business[] | undefined> {
  return await get<Business[]>(`${endpoints['business']}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createBusiness(business: BusinessRequest, picture: File): Promise<Business | undefined> {
  let formData = new FormData();
  formData.append('file', picture);
  formData.append('request', JSON.stringify(business));
  return await postForm<Business>(`${endpoints['business']}`, formData)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateBusiness(updateBusinessRequest: UpdateBusinessRequest): Promise<Business | undefined> {
  return await put<Business>(`${endpoints['business']}`, updateBusinessRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateBusinessPicture(updateImageRequest: UpdateImageRequest): Promise<string | undefined> {
  let formData = new FormData();
  formData.append('file', updateImageRequest.file);

  return await putForm<string>(`${endpoints['business']}/${updateImageRequest.businessId}/picture`, formData)
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function removeBusinessPicture(businessId: number): Promise<string | undefined> {
  return await httpDelete<string>(`${endpoints['business']}/${businessId}/picture`)
    .then((response) => {
      return response.stringBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getBusiness(businessUrlName: string): Promise<Business | undefined> {
  return await get<Business>(`${endpoints['business']}/${businessUrlName}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateClientSecret(clientSecretRequest: ClientSecretRequest): Promise<string | undefined> {
  return await put<string>(
    `${endpoints['business']}/clientSecret/${clientSecretRequest.businessId}`,
    clientSecretRequest,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
