import { Dispatch } from 'redux';
import { commercesService } from '../../services/commerces.service';
import {
  ADD_BUSINESS_ISSUERS,
  ADD_COMMERCES,
  ADD_ISSUERS,
  CREATE_BUSINESS_COMMERCE_FAILED,
  CREATE_BUSINESS_COMMERCE_SUCCESS,
  CREATE_COMMERCE_FAILED,
  CREATE_COMMERCE_SUCCESS,
  CreateBusinessCommerceTypes,
  CreateCommerceTypes,
  CREATING_BUSINESS_COMMERCE,
  CREATING_COMMERCE,
  DELETE_BUSINESS_COMMERCE_FAILED,
  DELETE_BUSINESS_COMMERCE_SUCCESS,
  DELETE_COMMERCE_FAILED,
  DELETE_COMMERCE_SUCCESS,
  DeleteBusinessCommerceTypes,
  DeleteCommerceTypes,
  DELETING_BUSINESS_COMMERCE,
  DELETING_COMMERCE,
  DESIRED_ISSUERS_FAILED,
  DESIRED_ISSUERS_SUCCESS,
  DesiredIssuersTypes,
  FETCH_BUSINESS_ISSUERS_FAILED,
  FETCH_COMMERCES_FAILED,
  FETCH_INSTALLMENTS_FAILED,
  FETCH_INSTALLMENTS_SUCCESS,
  FETCH_ISSUERS_FAILED,
  FetchBusinessIssuersTypes,
  FetchCommercesTypes,
  FETCHING_BUSINESS_ISSUERS,
  FETCHING_COMMERCES,
  FETCHING_INSTALLMENTS,
  FETCHING_ISSUERS,
  FetchIssuersTypes,
  InstallmentsTypes,
  SENDING_DESIRED_ISSUERS,
  UPDATE_BUSINESS_COMMERCE_FAILED,
  UPDATE_BUSINESS_COMMERCE_SUCCESS,
  UPDATE_COMMERCE_FAILED,
  UPDATE_COMMERCE_SUCCESS,
  UpdateBusinessCommerceTypes,
  UpdateCommerceTypes,
  UPDATING_BUSINESS_COMMERCE,
  UPDATING_COMMERCE,
} from '../config/ActionTypes';
import {
  BusinessIssuer,
  Commerce,
  CreateCommerceRequest,
  DesiredIssuersRequest,
  Issuer,
  UpdateCommerceRequest,
} from '../config/types';

//#region Fetch commerces

export const fetchCommerces = (businessId: number) => {
  return (dispatch: Dispatch<FetchCommercesTypes>) => {
    dispatch(loadingCommerces());

    return commercesService.fetchCommerces(businessId).then(
      (response) => {
        dispatch(addCommerces(response!));
      },
      (error) => {
        dispatch(commercesFailed(error));
      },
    );
  };
};

export const loadingCommerces = (): FetchCommercesTypes => ({
  type: FETCHING_COMMERCES,
});

export const addCommerces = (commerces: Commerce[]): FetchCommercesTypes => ({
  type: ADD_COMMERCES,
  commerces,
});

export const commercesFailed = (error: string): FetchCommercesTypes => ({
  type: FETCH_COMMERCES_FAILED,
  error,
});

//#endregion

//#region Create commerce

export const createCommerce = (createCommerceRequest: CreateCommerceRequest) => {
  return (dispatch: Dispatch<CreateCommerceTypes>) => {
    dispatch(creatingCommerce());

    return commercesService.createCommerce(createCommerceRequest).then(
      (response) => {
        dispatch(createCommerceSuccess(response!));
      },
      (error) => {
        dispatch(createCommerceFailed(error));
      },
    );
  };
};

export const creatingCommerce = (): CreateCommerceTypes => ({
  type: CREATING_COMMERCE,
});

export const createCommerceSuccess = (commerce: Commerce): CreateCommerceTypes => ({
  type: CREATE_COMMERCE_SUCCESS,
  commerce,
});

export const createCommerceFailed = (error: string): CreateCommerceTypes => ({
  type: CREATE_COMMERCE_FAILED,
  error,
});
//#endregion

//#region createBusinessCommerce
export const createBusinessCommerce = (createCommerceRequest: CreateCommerceRequest, businessId: number) => {
  return (dispatch: Dispatch<CreateBusinessCommerceTypes | CreateCommerceTypes>) => {
    dispatch(creatingBusinessCommerce());

    return commercesService.createCommerce(createCommerceRequest).then(
      (response) => {
        dispatch(createBusinessCommerceSuccess(response!, businessId));
      },
      (error) => {
        dispatch(createBusinessCommerceFailed(error));
      },
    );
  };
};

export const creatingBusinessCommerce = (): CreateBusinessCommerceTypes => ({
  type: CREATING_BUSINESS_COMMERCE,
});

export const createBusinessCommerceSuccess = (
  commerce: Commerce,
  businessId: number,
): CreateBusinessCommerceTypes => ({
  type: CREATE_BUSINESS_COMMERCE_SUCCESS,
  commerce,
  businessId,
});

export const createBusinessCommerceFailed = (error: string): CreateBusinessCommerceTypes => ({
  type: CREATE_BUSINESS_COMMERCE_FAILED,
  error,
});

//#endregion

//#region Update commerce

export const updateCommerce = (updateCommerceRequest: UpdateCommerceRequest) => {
  return (dispatch: Dispatch<UpdateCommerceTypes>) => {
    dispatch(updatingCommerce());

    return commercesService.updateCommerce(updateCommerceRequest).then(
      (response) => {
        dispatch(updateCommerceSuccess(response!));
      },
      (error) => {
        dispatch(updateCommerceFailed(error));
      },
    );
  };
};

export const updatingCommerce = (): UpdateCommerceTypes => ({
  type: UPDATING_COMMERCE,
});

export const updateCommerceSuccess = (commerce: Commerce): UpdateCommerceTypes => ({
  type: UPDATE_COMMERCE_SUCCESS,
  commerce,
});

export const updateCommerceFailed = (error: string): UpdateCommerceTypes => ({
  type: UPDATE_COMMERCE_FAILED,
  error,
});

//#endregion

//#region  updateBusinessCommerce

export const updateBusinessCommerce = (updateCommerceRequest: UpdateCommerceRequest, businessId: number) => {
  return (dispatch: Dispatch<UpdateBusinessCommerceTypes>) => {
    dispatch(updatingBusinessCommerce());
    return commercesService.updateCommerce(updateCommerceRequest).then(
      (response) => {
        dispatch(updateBusinessCommerceSuccess(response!, businessId));
      },
      (error) => {
        dispatch(updateBusinessCommerceFailed(error));
      },
    );
  };
};

export const updatingBusinessCommerce = (): UpdateBusinessCommerceTypes => ({
  type: UPDATING_BUSINESS_COMMERCE,
});

export const updateBusinessCommerceSuccess = (
  commerce: Commerce,
  businessId: number,
): UpdateBusinessCommerceTypes => ({
  type: UPDATE_BUSINESS_COMMERCE_SUCCESS,
  commerce,
  businessId,
});

export const updateBusinessCommerceFailed = (error: string): UpdateBusinessCommerceTypes => ({
  type: UPDATE_BUSINESS_COMMERCE_FAILED,
  error,
});

//#endregion

//#region Delete commerce

export const deleteCommerce = (businessId: number, commerceId: number, isLast: boolean) => {
  return (dispatch: Dispatch<DeleteCommerceTypes>) => {
    dispatch(deletingCommerce());

    return commercesService.deleteCommerce(businessId, commerceId).then(
      (response) => {
        dispatch(deleteCommerceSuccess(commerceId, isLast));
      },
      (error) => {
        dispatch(deleteCommerceFailed(error));
      },
    );
  };
};

export const deletingCommerce = (): DeleteCommerceTypes => ({
  type: DELETING_COMMERCE,
});

export const deleteCommerceSuccess = (commerceId: number, isLast: boolean): DeleteCommerceTypes => ({
  type: DELETE_COMMERCE_SUCCESS,
  commerceId,
  isLast,
});

export const deleteCommerceFailed = (error: string): DeleteCommerceTypes => ({
  type: DELETE_COMMERCE_FAILED,
  error,
});

export const deleteBusinessCommerce = (businessId: number, commerceId: number) => {
  return (dispatch: Dispatch<DeleteBusinessCommerceTypes>) => {
    dispatch(deletingBusinessCommerce());

    return commercesService.deleteCommerce(businessId, commerceId).then(
      (response) => {
        dispatch(deleteBusinessCommerceSuccess(commerceId, businessId));
      },
      (error) => {
        dispatch(deleteBusinessCommerceFailed(error));
      },
    );
  };
};

export const deletingBusinessCommerce = (): DeleteBusinessCommerceTypes => ({
  type: DELETING_BUSINESS_COMMERCE,
});

export const deleteBusinessCommerceSuccess = (
  commerceId: number,
  businessId: number,
): DeleteBusinessCommerceTypes => ({
  type: DELETE_BUSINESS_COMMERCE_SUCCESS,
  commerceId,
  businessId,
});

export const deleteBusinessCommerceFailed = (error: string): DeleteBusinessCommerceTypes => ({
  type: DELETE_BUSINESS_COMMERCE_FAILED,
  error,
});

//#endregion

//#region Fetch issuers

export const fetchIssuers = () => {
  return (dispatch: Dispatch<FetchIssuersTypes>) => {
    dispatch(loadingIssuers());

    return commercesService.fetchIssuers().then(
      (response) => {
        dispatch(addIssuers(response!));
      },
      (error) => {
        dispatch(issuersFailed(error));
      },
    );
  };
};

export const loadingIssuers = (): FetchIssuersTypes => ({
  type: FETCHING_ISSUERS,
});

export const addIssuers = (issuers: Issuer[]): FetchIssuersTypes => ({
  type: ADD_ISSUERS,
  issuers,
});

export const issuersFailed = (error: string): FetchIssuersTypes => ({
  type: FETCH_ISSUERS_FAILED,
  error,
});

//#endregion

//#region Fetch business' issuers

export const fetchBusinessIssuers = (businessId: number) => {
  return (dispatch: Dispatch<FetchBusinessIssuersTypes>) => {
    dispatch(loadingBusinessIssuers());

    return commercesService.fetchBusinessIssuers(businessId).then(
      (response) => {
        dispatch(addBusinessIssuers(response!));
      },
      (error) => {
        dispatch(issuersBusinessFailed(error));
      },
    );
  };
};

export const loadingBusinessIssuers = (): FetchBusinessIssuersTypes => ({
  type: FETCHING_BUSINESS_ISSUERS,
});

export const addBusinessIssuers = (issuers: BusinessIssuer[]): FetchBusinessIssuersTypes => ({
  type: ADD_BUSINESS_ISSUERS,
  issuers,
});

export const issuersBusinessFailed = (error: string): FetchBusinessIssuersTypes => ({
  type: FETCH_BUSINESS_ISSUERS_FAILED,
  error,
});

//#endregion

//#region Send desired issuers

export const sendDesiredIssuers = (businessId: number, issuers: DesiredIssuersRequest) => {
  return (dispatch: Dispatch<DesiredIssuersTypes>) => {
    dispatch(sendingDesiredIssuers());

    return commercesService.sendDesiredIssuers(businessId, issuers).then(
      (response) => {
        dispatch(desiredIssuersSuccess());
      },
      (error) => {
        dispatch(desiredIssuersFailed(error));
      },
    );
  };
};

export const sendingDesiredIssuers = (): DesiredIssuersTypes => ({
  type: SENDING_DESIRED_ISSUERS,
});

export const desiredIssuersSuccess = (): DesiredIssuersTypes => ({
  type: DESIRED_ISSUERS_SUCCESS,
});

export const desiredIssuersFailed = (error: string): DesiredIssuersTypes => ({
  type: DESIRED_ISSUERS_FAILED,
  error,
});

//#endregion

//#region fetch installments

export const fetchInstallments = () => {
  return (dispatch: Dispatch<InstallmentsTypes>) => {
    dispatch(fetchingInstallments());

    return commercesService.fetchPossibleInstallments().then(
      (response) => {
        dispatch(fetchingInstallmentsSuccess(response!));
      },
      (error) => {
        dispatch(fetchInstallmentsFailed(error));
      },
    );
  };
};

export const fetchingInstallments = (): InstallmentsTypes => ({
  type: FETCHING_INSTALLMENTS,
});

export const fetchingInstallmentsSuccess = (installments: number[]): InstallmentsTypes => ({
  type: FETCH_INSTALLMENTS_SUCCESS,
  installments,
});

export const fetchInstallmentsFailed = (error: string): InstallmentsTypes => ({
  type: FETCH_INSTALLMENTS_FAILED,
  error,
});

//#endregion
