import { Chip, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchInstallments } from '../../../store/action_creators/commerces.actions';
import { AppActions } from '../../../store/config/ActionTypes';
import { Commerce, RootState } from '../../../store/config/types';
import colors from '../../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helperText: {
      paddingTop: '0.5rem',
    },
    subtitle: {
      marginBottom: '0.6rem',
      color: colors.slateBlue,
      fontWeight: 500,
      lineHeight: 1.57,
      fontSize: '0.9375rem',
    },
    installmentsBox: {
      border: `1px solid ${colors.borderButtonSecondary}`,
      padding: '1.0625rem 1.25rem',
      borderRadius: '4px',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(2.8125rem, 1fr))',
      justifyItems: 'center',
      alignItems: 'center',
      gap: '0.9375rem',
    },
    installment: {
      flex: 1,
      textAlign: 'center',
      padding: '0.625rem',
    },
  }),
);

interface InstallmentsFieldProps {
  commerce: Commerce | null;
  selectedInstallments: number[];
  setSelectedInstallments(installments: number[]): void;
}

const mapStateToProps = (state: RootState) => ({
  commerces: state.commerces,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchInstallments: () => dispatch(fetchInstallments()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type PropsType = PropsFromRedux & InstallmentsFieldProps;

function InstallmentsField({
  commerces,
  fetchInstallments,
  commerce,
  selectedInstallments,
  setSelectedInstallments,
}: PropsType) {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      setSelectedInstallments(commerce ? commerce.values : [1]);
    }
  }, [loaded, setLoaded, setSelectedInstallments, commerce]);

  const classes = useStyles();

  useEffect(() => {
    if (
      !commerces.fetchingPossibleInstallments &&
      commerces.possibleInstallments === null &&
      commerces.fetchInstallmentsError === null
    ) {
      fetchInstallments();
    }
  }, [
    commerces.fetchingPossibleInstallments,
    commerces.possibleInstallments,
    commerces.fetchInstallmentsError,
    fetchInstallments,
  ]);

  useEffect(() => {
    if (!selectedInstallments.length) setSelectedInstallments([1]);
  }, [selectedInstallments.length, setSelectedInstallments]);

  const checkInstallment = (installment: number) => {
    const existingInstallment = selectedInstallments.find((i) => i === installment);

    if (existingInstallment) {
      setSelectedInstallments(selectedInstallments.filter((i) => i !== installment));
    } else {
      setSelectedInstallments([...selectedInstallments, installment]);
    }
  };

  return (
    <>
      {commerces.possibleInstallments && (
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Cuotas disponibles</Typography>
          <div className={classes.installmentsBox}>
            {commerces.possibleInstallments.map((i, index) => {
              const isSelected = selectedInstallments.find((ins) => ins === i) !== undefined;

              return (
                <Chip
                  key={index}
                  label={i}
                  clickable
                  color={isSelected ? 'primary' : 'default'}
                  onClick={() => checkInstallment(i)}
                />
              );
            })}
          </div>
        </Grid>
      )}
    </>
  );
}

export default connector(InstallmentsField);
