import { CircularProgress, Divider, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '../../assets/addIcon';
import DeleteIcon from '../../assets/deleteIcon';
import { deleteCommerce, fetchCommerces } from '../../store/action_creators/commerces.actions';
import { fetchLinks, fetchOneUseLinks } from '../../store/action_creators/links.actions';
import { DateSpan, LinkTypeEnum, PaymentsColumnSortEnum, SortEnum, Status } from '../../store/config/enums';
import { Commerce, RootState } from '../../store/config/types';
import { initialState as initialLinks } from '../../store/reducers/links';
import { CustomSnackbar } from '../CustomSnackbar';
import { CommerceDialog } from '../dialogs/CommerceDialog';
import { DeleteDialog } from '../dialogs/DeleteDialog';
import SingleCommerceDeleteTooltip from './SingleCommerceDeleteTooltip';

function Commerces() {
  const dispatch = useDispatch();
  const { auth, commerces } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deletingCommerce, setDeletingCommerce] = useState<boolean>(false);
  const [selectedCommerce, setSelectedCommerce] = useState<Commerce | null>(null);
  const [commerceToDelete, setCommerceToDelete] = useState<Commerce | null>(null);
  const filters = useMemo(
    () => ({
      dateSpan: DateSpan.LAST7DAYS,
      columnSort: PaymentsColumnSortEnum.Date,
      sortDirection: SortEnum.DESC,
    }),
    [],
  );

  const page = 1;
  const pageLimit = 7;
  const businessId = auth?.account?.business?.id ?? -1;

  useEffect(() => {
    if (
      auth.account &&
      !commerces.loadingCommerces &&
      commerces.commerces === null &&
      commerces.commercesErrorMessage === null
    ) {
      dispatch(fetchCommerces(auth.account.business.id!));
    }
  }, [
    auth.account,
    commerces.loadingCommerces,
    commerces.commerces,
    commerces.commercesErrorMessage,
    dispatch,
  ]);

  const createCommerce = () => {
    setSelectedCommerce(null);
    setOpen(true);
  };

  const updateCommerce = (commerce: Commerce) => {
    setSelectedCommerce(commerce);
    setOpen(true);
  };

  const beginDeleteCommerce = (commerce: Commerce) => {
    setCommerceToDelete(commerce);
    setDeleteOpen(true);
  };

  const confirmDeleteCommerce = () => {
    setDeletingCommerce(true);

    if (commerceToDelete && auth.account && commerces.commerces)
      dispatch(
        deleteCommerce(
          auth.account.business.id!,
          commerceToDelete?.issuerId,
          commerces.commerces.length === 1,
        ),
      );
  };

  const closeSnack = () => {
    setDeletingCommerce(false);

    if (commerces.deleteCommerceSuccess) {
      dispatch(
        fetchOneUseLinks(
          initialLinks.oneUseLinksFilters,
          page,
          pageLimit,
          businessId!,
          LinkTypeEnum.ONETIME,
          true,
        ),
      );
      dispatch(
        fetchLinks(initialLinks.linksFilters, page, pageLimit, businessId!, LinkTypeEnum.PERMANENT, true),
      );
      dispatch(fetchLinks(filters, page, pageLimit, businessId, LinkTypeEnum.ALL, true, true, true));
    }
    setDeleteOpen(false);
  };

  if (commerces.loadingCommerces) return <CircularProgress />;

  return (
    <>
      <div className="commerces-list">
        <div className="new-commerce" onClick={createCommerce}>
          <AddIcon />
          <Typography variant="subtitle2">Nuevo medio de pago</Typography>
        </div>
        {commerces.commerces &&
          commerces.commerces.map((commerce, index) => {
            return (
              <div className="commerce" onClick={() => updateCommerce(commerce)} key={index}>
                <div className="trash">
                  {commerces.commerces && commerces.commerces.length === 1 ? (
                    <SingleCommerceDeleteTooltip />
                  ) : (
                    <Tooltip title="Eliminar" placement="top" enterDelay={500}>
                      <span
                        onClick={(e: React.SyntheticEvent<any>) => {
                          e.stopPropagation();
                          beginDeleteCommerce(commerce);
                        }}
                      >
                        <DeleteIcon />
                      </span>
                    </Tooltip>
                  )}
                </div>
                <img src={commerce.issuerImageUrl} alt="logo" />
                <Divider orientation="horizontal" className="divider" />
                <div className="number">
                  <Typography noWrap className="commerce-text">
                    {commerce.issuerName}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
      <CommerceDialog open={open} setOpen={setOpen} commerce={selectedCommerce} />
      <DeleteDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        message={`¿Seguro que deseas eliminar el medio de pago ${commerceToDelete?.issuerName}?`}
        deleteAction={confirmDeleteCommerce}
        deleteDisabled={deletingCommerce}
      />
      <CustomSnackbar
        open={
          deletingCommerce &&
          (commerces.deleteCommerceSuccess || commerces.deleteCommerceErrorMessage !== null)
        }
        message={
          commerces.deleteCommerceSuccess
            ? 'Se borró el medio de pago correctamente'
            : 'Ocurrió un error al borrar el medio de pago'
        }
        handleClose={closeSnack}
        type={commerces.deleteCommerceSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </>
  );
}

export default Commerces;
