import { SubBusiness } from '../store/config/types';
import { get } from './base.service';
import { endpoints } from './endpoints';

export const subBusinessService = {
  fetchSubBusiness,
};

async function fetchSubBusiness(businessId: number): Promise<SubBusiness[] | undefined> {
  return await get<SubBusiness[]>(`${endpoints['subBusiness']}?businessId=${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
