import { Button, Link, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import schemas from '../../../data/schemas';
import { createCollaborator, updateCollaborator } from '../../../store/action_creators/collaborators.actions';
import { Status, UserTypeEnum } from '../../../store/config/enums';
import {
  Collaborator,
  CreateCollaboratorRequest,
  RootState,
  UpdateCollaboratorRequest,
} from '../../../store/config/types';
import { CustomSnackbar } from '../../CustomSnackbar';
import PasswordField from '../../PasswordField/PasswordField';
import { ReturnButton } from '../../ReturnButton';
import { ScreenTitle } from '../../ScreenTitle';
import { SubmitButton } from '../../SubmitButton';
import { ChangePasswordDialog } from '../../dialogs/ChangePasswordDialog';
import { FormTextField } from '../FormTextField';

interface UserState {
  collaborator: Collaborator | null;
}

interface Values {
  names: string;
  surnames: string;
  email: string;
  password: string;
  repeatPassword: string;
}

function SubUser() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userState = location.state as UserState;
  const client = location.state.client;
  const collaborator = userState?.collaborator;
  const { auth, collaborators } = useSelector((state: RootState) => state);
  const [creatingCollaborator, setCreatingCollaborator] = useState<boolean>(false);
  const [updatingCollaborator, setUpdatingCollaborator] = useState<boolean>(false);
  const [resetingPassword, setResetingPassword] = useState<boolean>(false);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);

  const isSuperAdmin = auth.account?.type === UserTypeEnum.SUPERADMIN;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const submitCollaborator = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      if (collaborator) {
        setUpdatingCollaborator(true);

        const updateCollaboratorRequest: UpdateCollaboratorRequest = {
          userId: collaborator.userId,
          email: collaborator.email,
          businessId: auth.account?.business?.id || -1,
          names: values.names,
          surnames: values.surnames,
          telephoneNo: collaborator.telephoneNo,
          type: 2,
        };

        dispatch(updateCollaborator(updateCollaboratorRequest));
      } else {
        setCreatingCollaborator(true);

        const createCollaboratorRequest: CreateCollaboratorRequest = {
          active: true,
          businessId: auth.account?.business?.id || -1,
          names: values.names,
          surnames: values.surnames,
          email: values.email,
          password: values.password,
          telephoneNo: '1',
        };

        dispatch(createCollaborator(createCollaboratorRequest));
      }
    }
  };

  const closeSnack = () => {
    if (collaborator) {
      setUpdatingCollaborator(false);
      setResetingPassword(false);
    } else {
      setCreatingCollaborator(false);
    }
    isSuperAdmin ? navigate('/manage-clients', { state: { client: client } }) : navigate(-1);
  };

  const submitButton = (
    <SubmitButton
      formId="collaboratorForm"
      loading={creatingCollaborator || updatingCollaborator}
      label={
        collaborator ? `Guardar ${!isMobile ? 'cambios' : ''}` : `Crear ${!isMobile ? 'colaborador' : ''}`
      }
    />
  );

  return (
    <div className="screen-container profile">
      <ScreenTitle
        title={collaborator ? 'Editar colaborador' : 'Nuevo colaborador'}
        hasReturnButton={!isMobile && !isSuperAdmin}
      >
        {isSuperAdmin && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/manage-clients', { state: { client: client } })}
          >
            Volver
          </Button>
        )}
        {!isMobile && submitButton}
      </ScreenTitle>
      <Paper elevation={2}>
        <Formik
          initialValues={{
            names: collaborator ? collaborator.names : '',
            surnames: collaborator ? collaborator.surnames : '',
            email: collaborator ? collaborator.email : '',
            password: collaborator ? 'Password1234!' : '',
            repeatPassword: collaborator ? 'Password1234!' : '',
          }}
          validationSchema={schemas.UserSchema}
          onSubmit={submitCollaborator}
        >
          <Form className="form" id="collaboratorForm">
            <Field
              className="form-row-first"
              name="names"
              component={FormTextField}
              type="text"
              placeholder="Nombres"
            />
            <Field
              className="form-row"
              name="surnames"
              component={FormTextField}
              type="text"
              placeholder="Apellidos"
            />
            <Field
              className="form-row"
              name="email"
              component={FormTextField}
              type="text"
              placeholder="Correo electrónico"
              disabled={!!collaborator}
            />
            {collaborator ? (
              <div className="link">
                <Link onClick={() => setPasswordOpen(true)}>
                  <u>Cambiar contraseña</u>
                </Link>
              </div>
            ) : (
              <>
                <PasswordField inputName="password" />
                <PasswordField inputName="repeatPassword" placeholder="Repetir contraseña" />
              </>
            )}
            <div className="many-actions-row">
              {isMobile && (
                <>
                  <ReturnButton />
                  {submitButton}
                </>
              )}
            </div>
            <CustomSnackbar
              open={
                creatingCollaborator &&
                (collaborators.createCollaboratorSuccess ||
                  collaborators.createCollaboratorErrorMessage !== null)
              }
              message={
                collaborators.createCollaboratorSuccess
                  ? 'Se creó el colaborador correctamente.'
                  : collaborators.createCollaboratorErrorMessage!
              }
              handleClose={closeSnack}
              type={collaborators.createCollaboratorSuccess ? Status.SUCCESS : Status.ERROR}
            />
            <CustomSnackbar
              open={
                updatingCollaborator &&
                (collaborators.updateCollaboratorSuccess ||
                  collaborators.updateCollaboratorErrorMessage !== null)
              }
              message={
                collaborators.updateCollaboratorSuccess
                  ? 'Se actualizó el colaborador correctamente.'
                  : collaborators.updateCollaboratorErrorMessage!
              }
              handleClose={closeSnack}
              type={collaborators.updateCollaboratorSuccess ? Status.SUCCESS : Status.ERROR}
            />
            <CustomSnackbar
              open={
                resetingPassword && (auth.resetPasswordSuccess || auth.resetPasswordErrorMessage !== null)
              }
              message={
                auth.resetPasswordSuccess
                  ? 'Se reinició la contraseña del colaborador correctamente.'
                  : 'Ocurrió un error al reiniciar la contraseña del colaborador.'
              }
              handleClose={closeSnack}
              type={auth.resetPasswordSuccess ? Status.SUCCESS : Status.ERROR}
            />
            <ChangePasswordDialog open={passwordOpen} setOpen={setPasswordOpen} collaborator={collaborator} />
          </Form>
        </Formik>
      </Paper>
    </div>
  );
}

export default SubUser;
