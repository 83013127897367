import {
  ADD_CLIENTS,
  ClientDataTypes,
  CommercesDataTypes,
  CREATE_BUSINESS_COMMERCE_FAILED,
  CREATE_BUSINESS_COMMERCE_SUCCESS,
  CREATING_BUSINESS_COMMERCE,
  DELETE_BUSINESS_COMMERCE_FAILED,
  DELETE_BUSINESS_COMMERCE_SUCCESS,
  DELETING_BUSINESS_COMMERCE,
  FETCH_CLIENTS_FAILED,
  FETCHING_CLIENTS,
  LogoutActionTypes,
  TOGGLE_CLIENT_BILLING_FAILED,
  TOGGLE_CLIENT_BILLING_SUCCESS,
  TOGGLING_CLIENT_BILLING,
  UPDATE_BUSINESS_COMMERCE_SUCCESS,
  UPDATING_BUSINESS_COMMERCE,
} from '../config/ActionTypes';
import { ClientsState } from '../config/types';

export const initialState: ClientsState = {
  loadingClients: false,
  clients: null,
  loadingClientsErrorMessage: null,
  updatingBilling: false,
  billingUpdateSuccess: false,
  billingUpdateError: null,
  creatingCommerces: false,
  creatingCommercesSuccess: false,
  creatingCommercesErrorMessage: null,
  updatingCommerces: false,
  updatingCommercesSuccess: false,
  updatingCommercesErrorMessage: null,
  deletingCommerces: false,
  deletingCommercesSuccess: false,
  deletingCommercesErrorMessage: null,
};

export function clientsReducer(
  state = initialState,
  action: ClientDataTypes | LogoutActionTypes | CommercesDataTypes,
): ClientsState {
  switch (action.type) {
    case FETCHING_CLIENTS:
      return {
        ...state,
        loadingClients: true,
        clients: null,
        loadingClientsErrorMessage: null,
      };
    case ADD_CLIENTS:
      return {
        ...state,
        loadingClients: false,
        clients: action.clients,
        loadingClientsErrorMessage: null,
      };
    case FETCH_CLIENTS_FAILED:
      return {
        ...state,
        loadingClients: false,
        clients: null,
        loadingClientsErrorMessage: action.error,
      };
    case TOGGLING_CLIENT_BILLING:
      return {
        ...state,
        updatingBilling: true,
        billingUpdateSuccess: false,
        billingUpdateError: null,
      };
    case TOGGLE_CLIENT_BILLING_SUCCESS:
      const updatedClients = state.clients?.map((cli) => {
        if (cli.id === action.request.id) {
          const newCli = cli;
          cli.billingEnabled = action.request.enabled;
          return newCli;
        } else return cli;
      });
      return {
        ...state,
        updatingBilling: false,
        billingUpdateSuccess: true,
        billingUpdateError: null,
        clients: updatedClients ?? null,
      };
    case TOGGLE_CLIENT_BILLING_FAILED:
      return {
        ...state,
        updatingBilling: false,
        billingUpdateSuccess: false,
        billingUpdateError: action.error,
      };

    case CREATING_BUSINESS_COMMERCE: {
      return {
        ...state,
        creatingCommerces: true,
        creatingCommercesSuccess: false,
        creatingCommercesErrorMessage: null,
      };
    }

    case CREATE_BUSINESS_COMMERCE_SUCCESS: {
      const clientsWithNewCommerce =
        state.clients &&
        state.clients.map((cli) => {
          if (cli.id === action.businessId) {
            cli.commerces ? cli.commerces.push(action.commerce) : (cli.commerces = [action.commerce]);
          }
          return cli;
        });
      return {
        ...state,
        creatingCommerces: false,
        creatingCommercesSuccess: true,
        creatingCommercesErrorMessage: null,
        clients: clientsWithNewCommerce,
      };
    }

    case CREATE_BUSINESS_COMMERCE_FAILED: {
      return {
        ...state,
        creatingCommerces: false,
        creatingCommercesSuccess: false,
        creatingCommercesErrorMessage: action.error,
      };
    }

    case UPDATING_BUSINESS_COMMERCE: {
      return {
        ...state,
        updatingCommerces: true,
        updatingCommercesSuccess: false,
        updatingCommercesErrorMessage: null,
      };
    }

    case UPDATE_BUSINESS_COMMERCE_SUCCESS: {
      const clientsWithUpdatedCommerce =
        state.clients &&
        state.clients.map((cli) => {
          if (cli.id === action.businessId) {
            const i = cli.commerces!.findIndex((co) => co.id === action.commerce.id);
            cli.commerces![i] = action.commerce;
          }
          return cli;
        });

      return {
        ...state,
        updatingCommerces: false,
        updatingCommercesSuccess: true,
        updatingCommercesErrorMessage: null,
        clients: clientsWithUpdatedCommerce,
      };
    }

    case DELETING_BUSINESS_COMMERCE: {
      return {
        ...state,
        deletingCommerces: true,
        deletingCommercesSuccess: false,
        deletingCommercesErrorMessage: null,
      };
    }

    case DELETE_BUSINESS_COMMERCE_SUCCESS: {
      const clientsWithDelCommerce =
        state.clients &&
        state.clients.map((cli) => {
          if (cli.id === action.businessId) {
            const filteredComms = cli.commerces!.filter((co) => co.id !== action.commerceId);
            cli.commerces = filteredComms;
          }
          return cli;
        });
      return {
        ...state,
        deletingCommerces: false,
        deletingCommercesSuccess: true,
        deletingCommercesErrorMessage: null,
        clients: clientsWithDelCommerce,
      };
    }

    case DELETE_BUSINESS_COMMERCE_FAILED: {
      return {
        ...state,
        deletingCommerces: false,
        deletingCommercesSuccess: false,
        deletingCommercesErrorMessage: action.error,
      };
    }

    default:
      return state;
  }
}
