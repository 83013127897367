import { Dispatch } from 'redux';
import { subBusinessService } from '../../services/subBusiness.service';
import {
  ADD_SUBBUSINESS,
  FETCH_SUBBUSINESS_FAILED,
  FETCHING_SUBBUSINESS,
  FetchSubBusinessTypes,
} from '../config/ActionTypes';
import { SubBusiness } from '../config/types';

export const fetchSubBusiness = (businessId: number) => {
  return (dispatch: Dispatch<FetchSubBusinessTypes>) => {
    dispatch(loadingSubBusiness());

    return subBusinessService.fetchSubBusiness(businessId).then(
      (response) => {
        dispatch(addSubBusiness(response!));
      },
      (error) => {
        dispatch(subBusinessFailed(error));
      },
    );
  };
};

export const loadingSubBusiness = (): FetchSubBusinessTypes => ({
  type: FETCHING_SUBBUSINESS,
});

export const addSubBusiness = (subBusiness: SubBusiness[]): FetchSubBusinessTypes => ({
  type: ADD_SUBBUSINESS,
  subBusiness,
});

export const subBusinessFailed = (error: string): FetchSubBusinessTypes => ({
  type: FETCH_SUBBUSINESS_FAILED,
  error,
});
