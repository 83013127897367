import { Card, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import EditIcon from '../../assets/EditIcon';
import { Commerce } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { StatusPill } from '../StatusPill';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subBusinessCard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '6px',
      width: '100%',
      padding: '0.625rem 1.25rem',
    },
    titleAndStatus: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      '& p': {
        textSize: '0.875rem',
        color: styles.slateBlue,
        fontWeight: +styles.semiBoldTextWeight,
        margin: 0,
      },
    },
    divider: {
      margin: '0.625rem 0',
      height: '1px',
    },
    paymentMethodContainer: {
      display: 'flex',
      height: '100%',
      '& img': {
        height: '100%',
      },
    },
    paymentMethodsAndAction: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    paymentMethods: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& i': {
        fontSize: '0.75rem',
        fontWeight: +styles.normalTextWeight,
        color: styles.iconColor,
      },
      '& img': {
        height: '1.5rem',
      },
    },
    paymentMethodDivider: {
      margin: '0 0.4rem',
    },
  }),
);

interface MobileSubBusinessCardProps {
  commerceName: string;
  paymentMethods: Commerce[];
}

function MobileSubBusinessCard({ commerceName, paymentMethods }: MobileSubBusinessCardProps) {
  const classes = useStyles();
  const noPaymentMethods = paymentMethods?.length === 0;
  const paymentMethodLimit = 3;
  const hasMorePaymentMethods = paymentMethods?.length > paymentMethodLimit;
  const reducedPaymentMethods = hasMorePaymentMethods
    ? paymentMethods.slice(0, paymentMethodLimit)
    : paymentMethods;

  return (
    <Card className={classes.subBusinessCard}>
      <div className={classes.titleAndStatus}>
        <p>{commerceName}</p>
        <StatusPill
          isCard
          label={noPaymentMethods ? 'Deshabilitado' : 'Habilitado'}
          color={noPaymentMethods ? 'info' : 'success'}
        />
      </div>
      <Divider className={classes.divider} flexItem />
      <div className={classes.paymentMethodsAndAction}>
        <div className={classes.paymentMethods}>
          {noPaymentMethods ? (
            <i>no hay medios de pagos activados</i>
          ) : (
            reducedPaymentMethods?.map((paymentMethod, index) => (
              <div className={classes.paymentMethodContainer} key={paymentMethod.id}>
                <img src={paymentMethod.issuerImageUrl} alt="" />
                {index !== paymentMethods?.length - 1 && (
                  <Divider className={classes.paymentMethodDivider} orientation="vertical" flexItem />
                )}
              </div>
            ))
          )}
          {hasMorePaymentMethods && (
            <StatusPill
              isCard
              label={`+${paymentMethods.length - paymentMethodLimit}`}
              color="info"
              customStyles={{ margin: '0 0 0 0.5rem', padding: '3px 0', color: styles.blackText }}
            />
          )}
        </div>
        <EditIcon />
      </div>
    </Card>
  );
}

export default MobileSubBusinessCard;
