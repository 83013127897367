import { Card, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyMessage } from '../../components/EmptyMessage';
import { ScreenTitle } from '../../components/ScreenTitle';
import MobileSubBusinessCard from '../../components/SubBusiness/MobileSubBusinessCard';
import SubBusinessCard from '../../components/SubBusiness/SubBusinessCard';
import { fetchSubBusiness } from '../../store/action_creators/subBusiness.actions';
import { RootState, SubBusiness } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    helperText: {
      textAlign: 'left',
      alignSelf: 'flex-start',
      margin: '0.5625rem 0 0 0',
      color: styles.blackText,
      fontSize: '0.875rem',
      fontWeight: +styles.normalTextWeight,
    },
    container: {
      width: '100%',
      padding: '3rem',
      marginTop: '1rem',
    },
    subBusinessContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1rem',
      gap: '1.0625rem',
    },
  }),
);

function SplitPayments() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { auth, subBusiness } = useSelector((state: RootState) => state);
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const businessId = auth.account?.business.id!;

  useEffect(() => {
    if (
      auth.account &&
      !subBusiness.loadingSubBusiness &&
      subBusiness.subBusiness === null &&
      subBusiness.subBusinessErrorMessage === null
    ) {
      dispatch(fetchSubBusiness(businessId));
    }
  }, [
    auth.account,
    subBusiness.loadingSubBusiness,
    subBusiness.subBusiness,
    subBusiness.subBusinessErrorMessage,
    dispatch,
    businessId,
  ]);

  return (
    <div className="screen-container">
      <div className={classes.screenTitle}>
        <ScreenTitle
          title="Split payments"
          formId={'split-payments'}
          submitButtonText="Nuevo comercio"
          {...(!isMobile && { elementsAmount: subBusiness.subBusiness?.length })}
        />
      </div>
      <p className={classes.helperText}>Crea y configura los comercios con los que dividirás los pagos</p>
      {subBusiness.subBusiness?.length === 0 ? (
        <Card className={classes.container}>
          <EmptyMessage
            title="Aún no has configurado ningún comercio"
            message="Crea uno ahora para empezar a usar split payments"
          />
        </Card>
      ) : (
        <div className={classes.subBusinessContainer}>
          {subBusiness.subBusiness?.map((subBusiness: SubBusiness) =>
            isMobile ? (
              <MobileSubBusinessCard
                key={subBusiness.name}
                commerceName={subBusiness.name}
                paymentMethods={subBusiness.commercesDto}
              />
            ) : (
              <SubBusinessCard
                key={subBusiness.name}
                commerceName={subBusiness.name}
                paymentMethods={subBusiness.commercesDto}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
}

export default SplitPayments;
