interface CsvHeader {
  key: string;
  label: string;
}

export const csvHeaders: CsvHeader[] = [
  { label: 'Estado', key: 'status' },
  { label: 'Fecha', key: 'date' },
  { label: 'Descripción', key: 'description' },
  { label: 'Tipo de link', key: 'linkType' },
  { label: 'Medio de pago', key: 'issuerName' },
  { label: 'Últimos 4 dígitos', key: 'maskedPan' },
  { label: 'Tipo de tarjeta', key: 'cardType' },
  { label: 'Importe', key: 'amount' },
  { label: 'Cuotas', key: 'installments' },
  { label: 'Nro. Autorización', key: 'authorization' },
  { label: 'Ticket', key: 'ticket' },
  { label: 'Referencia link', key: 'referencePayment' },
  { label: 'Referencia usuario', key: 'clientComments' },
];
